import { Controller } from "stimulus"
import AxiosRequest from "../utils/axios-request"
import UiKit from 'uikit'

export default class extends Controller
  myfavourite: ->
    modal = @element.parentNode.parentNode.parentNode
    iditem = @data.get('item')
    idcustomer = @data.get('idcustomer')
    dat = favourite: {item_id: iditem, customer_id: idcustomer}
    AxiosRequest('/customers/favourites', dat, 'post').then ({data}) =>
      if modal
        UiKit.modal(modal).hide();
        document.getElementById(modal.id + '-favourite').classList.remove('uk-invisible')
        document.getElementById('delete-favorite-' + modal.id).classList.replace('uk-hidden', 'uk-visible')
        document.getElementById('add-favorite-' + modal.id).classList.replace('uk-visible', 'uk-hidden')
      Swal.fire({
        title: data.message
        icon: 'success'
        toast: true
        position: 'top-end'
        timer: 2000
      }).then ->
        console.info 'success'
