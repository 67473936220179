import {Controller} from "stimulus"
import AxiosRequest from "../utils/axios-request"
import Moment from 'moment'
import SecureLS from 'secure-ls'

export default class extends Controller
  @targets: ['form']
  connect: ->
    @env = @data.get 'env'
    if JSON.parse @env
      route = 'sandbox'
    else
      route = 'production'

    AxiosRequest("https://#{route}.wompi.co/v1/transactions/#{@data.get 'id'}").then ({data}) =>
      {data} = data
      # Borramos la que hay en el ls
      ls = new SecureLS()
      ls.remove('cart')
      # Borramos el promotional code cuando t.o.d.o termine
      ls.remove('discount')
      status = document.getElementById('status')
      status.innerText = data.status
      reference = document.getElementById('reference')
      reference.innerText = data.reference
      value = document.getElementById('value')
      value.innerText = (data.amount_in_cents/100).toLocaleString('es-LA', {
        style: 'currency',
        currency: 'COP',
      })
      currency = document.getElementById('currency')
      currency.innerText = data.currency
      date = document.getElementById('date')
      date.innerText = Moment(data.created_at)
      payment = document.getElementById('payment')
      payment.innerText = data.payment_method_type
      card = document.getElementById('card')
      card.innerText = data.payment_method.extra.name
      installments = document.getElementById('installments')
      installments.innerText = data.payment_method.installments if data.payment_method.installments
