import {Controller} from "stimulus"

export default class extends Controller
  @targets: ["source"]
  copy: ->
    dummy = document.createElement('input')
    dummy.value = @data.get('url')
    document.body.appendChild(dummy)
    dummy.select()
    if document.execCommand('copy')
      Swal.fire({
        title: 'Copiado'
        timer: 1500
        # showConfirmButton: false
      })
    else
      Swal.fire 'Error al copiar'
    document.body.removeChild(dummy)
