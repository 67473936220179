import {Controller} from "stimulus"
import AxiosRequest from "../utils/axios-request"

export default class extends Controller
  @targets = ['cause', 'profit']
  connect: ->
    @dev = @.data.get 'dev'
    @item = @.data.get 'iditem'
    @key = @.data.get 'key'
    @f_price = parseInt(@.data.get 'f_price')
    @p_price = parseInt(@.data.get 'p_price')
    @f_tax = parseInt(@.data.get 'f_tax')
    @p_tax = parseInt(@.data.get 'p_tax')
    if JSON.parse @dev
      router_api = 'http://dev.fenixrepuestos.com:3000'
    else
      router_api = 'http://fenixrepuestos.com'
    AxiosRequest("#{router_api}api/v1/item_commission", {security_key: @key, iditem: @item, final_price: @f_price, purchase_price: @p_price, purchase_tax_id: @p_tax, extracted_tax_id: @f_tax}, 'post').then ({data}) =>
      cause = new Intl.NumberFormat().format(data.cause)
      profit = new Intl.NumberFormat().format(data.profit)
      @causeTarget.textContent = "$ #{cause}"
      @profitTarget.textContent = "$ #{profit}"
