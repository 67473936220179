import {Controller} from "stimulus"
import AxiosRequest from "../utils/axios-request"

defaultConfig =
  confirmButtonText: 'Siguiente'
  cancelButtonText: 'Cancelar'
  showCancelButton: true
  allowOutsideClick: false

export default class extends Controller
  subscription: ->
    request_swal = Swal.fire {
      defaultConfig...
      title: 'Ingrese su dirección de correo electrónico'
      input: 'email'
      validationMessage: 'Ingresa un correo válido'
      inputAttributes:
        autocapitalize: 'off'
      customClass:
        confirmButton: 'uk-button uk-button-primary uk-margin-small-right'
        cancelButton: 'uk-button uk-button-danger'
      buttonsStyling: false
    }
    request_swal.then ({value}) ->
      if value?
        AxiosRequest('/subscription', {subscription: {email: value}}, 'post').then ({data}) ->
          console.log 'subscripition'
          Swal.fire(data.message)
