require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()

require 'channels'
require 'trix'
#require '@rails/actiontext'

import UiKit from 'uikit'
import UiIcons from 'uikit/dist/js/uikit-icons.min'
import 'controllers'

# Componentes de view components
import "../components"

UiKit.use(UiIcons)
#import "stylesheets"
window.Swal = require 'sweetalert2/dist/sweetalert2.min'
